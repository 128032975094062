<template>

    <CRow>
        <CCol sm="12">
            <TopHeadline :headerTitle="$t('common.orderDetails')" :backTitle="$t('menu.backToOrdersPage')" :onGoBack="onGoBack">                
                <template v-slot:headerRight>

                    <button v-if="showConfirmButton" type="button" name="delete" class="btn btn-success" @click="onConfirmOrder">
                        {{ $t('pages.orders.confirmOrder') }}
                    </button>
                    <button v-if="showCancelButton" type="button" name="delete" class="btn btn-success" @click="onCancelOrder">
                        {{ $t('pages.orders.cancelOrder') }}
                    </button>
                    <button v-if="showShipButton" type="button" name="delete" class="btn btn-success" @click="onShipOrder">
                        {{ $t('pages.orders.shipOrder') }}
                    </button>
                    <button v-if="showDeliverButton" type="button" name="delete" class="btn btn-success" @click="onDeliverOrder">
                        {{ $t('pages.orders.deliverOrder') }}
                    </button>
                </template>
            </TopHeadline>

            <Accordion :headerTitle="$t('common.generalInfo')" v-if="orderDetail != null">
                <template v-slot:body>
                    <CRow>
                        <CCol sm="6">
                            <CForm>
                                <CInput :label="$t('pages.orders.orderNumber')" :value="orderDetail.orderNumber" horizontal></CInput>
                                <CInput :label="$t('pages.orders.orderDate')" :value="formatDate(orderDetail.createdDate)" horizontal></CInput>
                                <CInput :label="$t('common.amount')" :value="orderDetail.totalPrice" horizontal></CInput>
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3"> {{$t('common.status')}} </label>
                                    <div class="col-sm-9">
                                        <CButton class="btn" :class="statusButtonCss(orderDetail.status)">{{$t(`pages.orders.orderStatus_${orderDetail.status}`)}}</CButton>
                                    </div></div>
                            </CForm>
                        </CCol>

                        <CCol sm="6" v-if="orderDetail.shippingAddress != null">
                            <h5>{{$t('common.shippingInfo')}}</h5>
                            <CForm>
                                <CInput :label="$t('common.name')" :value="orderDetail.shippingAddress.fullName" horizontal></CInput>
                                <CInput :label="$t('common.phone')" :value="orderDetail.shippingAddress.phoneNumber" horizontal></CInput>
                                <CInput :label="$t('common.address')" :value="orderDetail.shippingAddress.streetAddress" horizontal></CInput>
                            </CForm>
                        </CCol>
                    </CRow>
                </template>
            </Accordion>


            <CCard v-if="orderDetail != null">
                <CCardHeader>
                    <slot name="header">
                        <CIcon name="cil-grid" />
                        {{ $t('common.orderDetails') }}
                    </slot>
                </CCardHeader>
                <CCardBody>
                    <CDataTable :items="orderDetail.orderItems"
                                :fields="fields"
                                hover
                                striped
                                border
                                small
                                fixed>

                        <template #price_text="{item}">
                            <td class="text-right">
                                {{$func.formatCurrency(item.price)}}
                            </td>
                            
                        </template>
                        <template #amount="{item}">
                            <td class="text-right">
                                {{$func.formatCurrency(item.price * item.quantity)}}
                            </td>
                        </template>

                        <template #unit="{item}">
                            <td>
                                {{item.unitModel != null ? item.unitModel.name : ''}}
                            </td>
                        </template>

                        <template #availableQty="{item}">
                            <td class="text-right font-weight-bold">
                                {{item.availableQty}}
                            </td>
                        </template>

                        <template #actions="{item}">
                            <td class="py-2 text-right">
                                <button type="button" class="btn btn-success" style="padding:0.1rem 0.3rem" v-if="item.availableQty < item.quantity" @click="onAddProductToInventory(item)">Nhập kho</button>
                            </td>
                        </template>
                    </CDataTable>
                </CCardBody>
            </CCard>

            <Confirmation ref="confirmation"></Confirmation>
        </CCol>
    </CRow>

</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex'
    import i18n from '@/plugins/i18n'

    import Accordion from '@/components/Accordion.vue'
    import TopHeadline from '@/components/TopHeadline.vue'
    import Confirmation from '@/components/Confirmation.vue'
    import OrderStatus from '@/helpers/OrderStatus'

    export default {
        name: 'OrderDetail',
        data() {
            return {
                horizontal: { label: 'col-3', input: 'col-9' },
                files: null,
                itemsPerPage: 10,
                warningModal: false,
                fields: [                      // Array of column object data
                    //{ key: 'id', label: 'Picture' },
                    { key: 'productName', label: i18n.t('pages.product.productName') },
                    { key: 'price_text', label: i18n.t('common.price'), _classes: 'text-right' },
                    { key: 'quantity', label: i18n.t('common.quantity'), _classes: 'text-right' },
                    { key: 'unit', label: i18n.t('common.unit') },
                    { key: 'availableQty', label: i18n.t('pages.product.availableQuantity'), _classes: 'text-right'},
                    { key: 'amount', label: i18n.t('common.amount'), _classes: 'text-right' },
                    { key: 'actions', label: i18n.t('common.action'), _classes: 'text-right' },
                ]
            }
        },
        components: {
            Accordion,
            TopHeadline,
            Confirmation
        },
        computed: {
            ...mapState('orders', ['orderDetail', 'isLoadingOrderDetail', 'canUpdateOrderState']),
            showConfirmButton() {
                if (!this.orderDetail)
                    return false;
                return this.orderDetail.status == OrderStatus.Pending;
            },
            showCancelButton() {
                if (!this.orderDetail)
                    return false;
                return this.orderDetail.status == OrderStatus.Pending || this.orderDetail.status == OrderStatus.Approved || this.orderDetail.status == OrderStatus.Shipped;
            },
            showShipButton() {
                if (!this.orderDetail)
                    return false;
                return this.orderDetail.status == OrderStatus.Approved;
            },
            showDeliverButton() {
                if (!this.orderDetail)
                    return false;
                return this.orderDetail.status == OrderStatus.Shipped;
            },
            showReturnButton() {
                if (!this.orderDetail)
                    return false;
                return this.orderDetail.status == OrderStatus.Delivered;
            },
            
        },
        methods: {
            ...mapActions('orders', ['getOrderById','changeOrderStatus']),
            onGoBack() {
                this.$router.push('/orders');
            },
            formatDate(date) {
                if (date === undefined)
                    return '';
                return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
            },

            async onChangeStatus({ orderId, newOrderStatus }) {
                //console.log("CHANGE STATUS", this.canUpdateOrderState);
                //if (!this.canUpdateOrderState)
                //    return;
                this.$refs.confirmation.show(i18n.t('pages.orders.changeStatusConfirmation'), async () => {
                    var retVal = await this.changeOrderStatus({ orderId, newOrderStatus });
                    if (retVal == true) {
                        this.onGoBack();
                    }
                });
            },
            statusButtonCss(status) {
                if (status == OrderStatus.Pending)
                    return "btn-danger";
                if (status == OrderStatus.Approved)
                    return "btn-primary";
                if (status == OrderStatus.Shipped)
                    return "btn-info";
                if (status == OrderStatus.Delivered)
                    return "btn-success";
                if (status == OrderStatus.Canceled)
                    return "btn-warning";
                if (status == OrderStatus.Returned)
                    return "btn-dark";
            },

            async onConfirmOrder() {
                await this.onChangeStatus({ orderId: this.orderDetail.id, newOrderStatus: OrderStatus.Approved });
            },
            async onCancelOrder() {
                await this.onChangeStatus({ orderId: this.orderDetail.id, newOrderStatus: OrderStatus.Canceled });
            },
            async onShipOrder() {
                await this.onChangeStatus({ orderId: this.orderDetail.id, newOrderStatus: OrderStatus.Shipped });
            },
            async onDeliverOrder() {
                await this.onChangeStatus({ orderId: this.orderDetail.id, newOrderStatus: OrderStatus.Delivered });
            },
            async onAddProductToInventory(item) {
                console.log("Nhap kho")
                console.log('Nhap Kho', item)
                if (this.orderDetail != null) {
                    this.$router.push({ path: '/warehouses/inventory/edit', query: { p: item.productId, ag: this.orderDetail.agentId } })
                }
            }
        },
        async mounted() {
            await this.getOrderById(this.$route.params.id);
        }
    }
</script>
<style>
    .product-not-enough {
        color: #fff !important;
        background:brown !important;
    }
</style>